<template>
  <div class="preview-wrap">
    <div class="main">
      <div class="logo">
        <img src="@/assets/prevLogo.png" alt="" class="logo-img">
      </div>
      <div class="cont">
        <div class="item" v-for="(item, idx) in list" :key="idx" @click="toPrevPage(item)"
          >
          <div class="label">{{ item.label }}</div>
          <div class="desc" >
            <div class="desc-tit">{{ item.tit }}</div>
            <div class="desc-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="btn" @click="toPlatform">
          <img src="@/assets/prevBtn.png" alt=""  class="icon-img">
          <span class="text">登录工作台</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';


export default {
  components: {},
  data() {
    return {
      active: '政策信息',
      list: [{
        label: '政策信息',
        tit: '查政策领补助',
        text: '管家式服务, 助力企业开源节流提升行业竞争力.',
      }, {
        label: '知识产权',
        tit: '查政策领补助',
        text: '管家式服务, 助力企业开源节流提升行业竞争力.',
      }, {
        label: '产业技术',
        tit: '查政策领补助',
        text: '管家式服务, 助力企业开源节流提升行业竞争力.',
      }, {
        label: '高端人才',
        tit: '查政策领补助',
        text: '管家式服务, 助力企业开源节流提升行业竞争力.',
      }, {
        label: '政务数据',
        tit: '查政策领补助',
        text: '管家式服务, 助力企业开源节流提升行业竞争力.',
      }]
    }
  },
  computed:{
    ...mapState(['userinfo'])
  },
  methods: {
    toPlatform() {
      // 
      if (this.userinfo) {
        this.$router.push('/work/home')
      }
      else {
        this.$router.push('/login')
      }
    },
    toPrevPage(item) {
      switch(item.label) {
        case '政策信息':
          this.$router.push('/prev/policy')
        break;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.preview-wrap {
}
.main {
  margin: auto;
  .logo {
    width: 268px;
    margin: auto;
    margin-top: 72px;
    margin-bottom: 62px;

    .logo-img {
      width: 100%;
    }
  }

  .cont {
    width: 870px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    position: relative;

    .item {
      flex: 0 1 auto;
      padding-bottom: 166px;

      &:nth-child(1) {
        .desc {
          &:after {
            left: calc(0% + 20% / 2 - 15px);
          }
        }
      }

      &:nth-child(2) {
        .desc {
          &:after {
            left: calc(20% + 20% / 2 - 15px);
          }
        }
      }

      &:nth-child(3) {
        .desc {
          &:after {
            left: calc(40% + 20% / 2 - 15px);
          }
        }
      }

      &:nth-child(4) {
        .desc {
          &:after {
            left: calc(60% + 20% / 2 - 15px);
          }
        }
      }

      &:nth-child(5) {
        .desc {
          &:after {
            left: calc(80% + 20% / 2 - 15px);
          }
        }
      }

      &:hover {
        .label {
          background: #23a0fd;
          color: #fff;
        }
        .desc {
          visibility: visible;
        }
      }

      .label {
        padding: 0 12px;
        font-size: 28px;
        color: #23a0fd;
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
      }

      .desc {
        position: absolute;
        left: 0px;
        top: 80px;
        width: 100%;
        visibility: hidden;
        // margin-top: 30px;
        background: #f3f6fe;
        padding: 24px 52px;

        &:after {
          position: absolute;
          bottom: 100%;
          content: ' ';
          display: block;
          border: 15px solid transparent;
          border-bottom-color: #f3f6fe;
        }

        .desc-tit {
          font-size: 36px;
          color: #666666;
          margin-bottom: 12px;
        }

        .desc-text {
          font-size: 23px;
          color: #666;
        }
      }
    }
  }

  .footer {
    margin-top: 72px;
    margin-bottom:150px;
    // text-align: center;
    //  display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: auto;
      border-radius: 10px;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);
      padding: 0 16px;
      height: 55px;
      display: flex;
      align-items: center;
      background: #23a0fd;
      cursor: pointer;

      .text {
        font-size: 30px;
        color: #fff;
      }


      .icon-img {
        width: 30px;
        margin-right: 12px;
      }
    }
  }
}</style>